import React from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import Related from '../components/relatedProds'

library.add(fat)

const ProductTemplate = ({ data }) => {
  const post = data.markdownRemark
  const hero =  data.heroImage
  
  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={hero?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.description}
          type="root"
        />
      </div>

      <section>
        <MDBContainer>
          <MDBRow>
            <MDBCol lg='12'>
              <div className="font-w-400 text-xs-extra-large text-opening-para"> {post.frontmatter.openingpara} </div> 
              <div className="richtext divlink mt-3" dangerouslySetInnerHTML={{ __html: post.html }} /> 
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      {post.frontmatter.section.map((sections, index) => (
        <section className="bg-light-blue-prods" key={index}>
          <MDBContainer>
            <MDBRow>
              <MDBCol md="3" className="pr-5">
                <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium"> {sections.title} </h3> 
                <p className="text-medium text-gray-dark mt-3"> {sections.description} </p> 
                </MDBCol>

              <MDBCol md="9">
                <MDBRow>
                  {sections.feature.map((features, index) => (
                    <MDBCol md="6" className="pb-5 item-prods" key={index}>
                      <div className="feature-icon">
                        <FontAwesomeIcon
                          icon={['fat', features.icon]}
                          size="3x"
                        />
                      </div>
                      <p className="text-medium text-blue-dark font-w-700"> {features.title} </p> 
                      <p className="text-medium text-gray-dark">
                        {features.description}
                        {features.link && (
                          <span className="text-small">
                            <Link to={features.link} className="effect"> {features.linktext} </Link> 
                          </span>
                        )}
                      </p>
                    </MDBCol>
                  ))}
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      ))}

      {post.frontmatter.related.length >= 1 && (
        <section className="bg-gray-light">
          <MDBContainer>
            <MDBRow>
              <MDBCol md="12" className="mb-5">
                <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium"> Related solutions </h3> 
              </MDBCol>
            </MDBRow>

            <MDBRow>
              {post.frontmatter.related.map((relate, index) => {
                return (
                  <Related
                    key={index}
                    collg="4"
                    colmd="6"
                    title={relate.title}
                    image={relate.image.childImageSharp.gatsbyImageData}
                    suite={post.frontmatter.suite}
                    description={relate.description}
                    linktext={relate.linktext}
                    link={relate.link}
                  />
                )
              })}
            </MDBRow>
          </MDBContainer>
        </section>
      )}
    </Layout>
  )
}
export default ProductTemplate

export function Head({ data, location }) {
  const heroImage = data.heroImage
  const post = data.markdownRemark

  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "products" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description 
        }
        template
        title
        subtitle
        description
        alttext
        openingpara
        suite
        headline {
          title
          icon
          description
          linktext
          link
          resource {
            publicURL
          }
        }
        section {
          title
          description
          linktext
          link
          feature {
            title
            icon
            description
            linktext
            link
          }
        }
        related {
          title
          image {
          childImageSharp {
            gatsbyImageData(width: 50, quality: 90) 
          }
        }
          description
          linktext
          link
        }
      }
    }
    heroImage: file(name: { eq: "solution-banner" }) {
      childImageSharp {
        gatsbyImageData(width: 1980, quality: 90)
      }
    }  
  }
`
